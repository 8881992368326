/* .report--view--none {
  background: url('/public/images/icon-file-large.svg');
}

.report--view--list select {
  margin-top: 8px;
  padding: 8px;
  border-radius: 20px;
}

.reports--view .error-details .error-details--subheader {
  margin-top: 8px;
} */
.reports--view > .page--content {
  display: initial;
}
