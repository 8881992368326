.feedback .page--content {
  display: block;
  max-width: 1140px;
}

.feedback .panel-content {
  flex-direction: column;
}

.feedback--directions {
  margin-top: 24px;
}

.feedback--directions p {
  margin-top: 12px;
  margin-bottom: 24px;
}
