.radio-list-container {
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  padding-right: 7px;
  column-gap: 20px; /* not relevant for flex column but desired for other layouts */
}

.radio-list-option {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  column-gap: 5px;
}

.radio-list-option label {
  width: 100%;
  font-family: Roboto, sans-serif;
}

.field-radio-list .horizontal-layout {
  flex-direction: row;
}
