.inner-grid {
  grid-template-columns: repeat(12, 1fr);
  gap: 20px;
  display: grid;
}

.empty-grid-content {
  grid-column: 4 / span 6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  aspect-ratio: 1 / 1;
}

.empty-grid-content p {
  margin-bottom: 24px;
  font-family: Quicksand, sans-serif;
  font-size: 50px;
  line-height: 60px;
  color: var(--disabled--text);
}

.empty-grid-content a {
  color: var(--primary--navy-blue);
}

.file--icon {
  background: url('/public/images/icon-file-large.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

@media (max-width: 720px) {
  .empty-grid-content {
    grid-column: 1 / span 12;
    margin-bottom: 24px;
  }

  .empty-grid-content p {
    font-size: 28px;
    line-height: 38px;
  }
}
