.info--panel--parent {
  gap: 12px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding: 32px;
  border-radius: 14px;
  background-color: var(--positive--background-green);
}

.info--panel--parent--pending {
  gap: 12px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  padding: 32px;
  border-radius: 14px;
  background-color: var(--background--gray);
}

.info--panel--icon {
  flex-grow: 0;
  flex-shrink: 0;
  width: 26px;
  height: 26px;
  background: url('/public/images/icon-completed.svg');
  align-self: self-start;
  background-size: contain;
}

.info--panel--pending--icon {
  flex-grow: 0;
  flex-shrink: 0;
  width: 26px;
  height: 26px;
  background: url('/public/images/icon-pending.svg');
  align-self: self-start;
  background-size: contain;
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.info--panel--message {
  font-family: Quicksand, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: var(--ink--medium);
}
