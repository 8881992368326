.files--upload--info {
  grid-column: 1 / span 8;
  margin-bottom: 24px;
  padding: 24px;
  border-radius: 20px;
  background: var(--background--light-turquoise);
  font-size: 16px;
  line-height: 20px;
}

.files--upload--info p {
  margin-bottom: 8px;
  font-family: Quicksand, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.files--upload--info--details {
  padding: 22px 24px;
  border-radius: 20px;
  background: white;
}

.files--upload--info--details-container {
  gap: 8px;
  display: flex;
  justify-content: center;
}

.files--upload--info--legend {
  text-align: center;
}

.files--upload--info--legend-example {
  font-family: Quicksand, sans-serif;
  font-size: 50px;
  font-weight: 600;
  line-height: 60px;
  color: var(--primary--navy-blue);
}

#files-upload-info-legend-example-school-district {
  width: 100px;

  --line-height: 0;
}

#files-upload-info-legend-example-data-type {
  width: 335px;

  --line-height: 0;
}

#files-upload-info-legend-example-optional-text {
  width: 370px;

  --line-height: 0;
}

#files-upload-info-legend-example-file-extension {
  width: 83px;

  --line-height: 0;
}

.files--upload--info--legend-value::after {
  content: '';
  display: block;
  height: 8px;
  padding-right: 4px;
  padding-left: 4px;
  border-right: 0.5px solid;
  border-bottom: 0.5px solid;
  border-left: 0.5px solid;
  color: var(--ink--medium);
}

.files--upload--info--legend-label {
  position: relative;
  left: -10px;
  width: calc(100% + 20px);
  margin-top: var(--line-height);
  padding-top: 8px;
  padding-right: 1.5px;
  padding-left: 1.5px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: var(--ink--medium);
}

.files--upload--info--legend-label::before {
  content: '';
  position: absolute;
  top: calc(0px - var(--line-height));
  left: 50%;
  display: block;
  width: 0.5px;
  height: var(--line-height);
  background: var(--ink--medium);
}

.files--upload--info--meta-container {
  gap: 0 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.files--upload--info--meta {
  margin-top: 24px;
}

.files--upload--info--meta label {
  font-weight: 500;
}

.files--upload--info--meta ul {
  list-style-position: inside;
}

.files--upload--info--meta ul li {
  padding-left: 10px;
}

@media (max-width: 1450px) {
  .files--upload--info--details-container {
    gap: 3px;
  }

  .files--upload--info--legend-example {
    font-size: 30px;
    font-weight: 600;
    line-height: 32px;
  }

  #files-upload-info-legend-example-school-district {
    width: 62px;
  }

  #files-upload-info-legend-example-data-type {
    width: 160px;

    --line-height: 59px;
  }

  #files-upload-info-legend-example-optional-text {
    width: 225px;

    --line-height: 32px;
  }

  #files-upload-info-legend-example-file-extension {
    width: 53px;

    --line-height: 93px;
  }
}

@media (max-width: 1030px) {
  .files--upload--info--details-container {
    gap: 3px;
  }

  .files--upload--info--legend-example {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
  }

  #files-upload-info-legend-example-school-district {
    width: 37px;
  }

  #files-upload-info-legend-example-data-type {
    width: 94px;

    --line-height: 59px;
  }

  #files-upload-info-legend-example-optional-text {
    width: 135px;

    --line-height: 32px;
  }

  #files-upload-info-legend-example-file-extension {
    width: 29px;

    --line-height: 93px;
  }
}

@media (max-width: 900px) {
  .files--upload--info {
    grid-column: 1 / span 12;
  }
}

@media (max-width: 720px) {
  .files--upload--info {
    grid-column: 1 / span 12;
  }
}
