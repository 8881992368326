.data-input--district-school-info--charter-schools--authorizing-organizations--list .content-outer-container {
  grid-column: 1 / span 8;
  margin-bottom: 24px;
  padding: 24px;
  border-radius: 20px;
  background: var(--background--light-turquoise);
  font-size: 16px;
  line-height: 20px;
}

.data-input--district-school-info--charter-schools--authorizing-organizations--list .content-inner-container {
  padding: 22px 24px;
  border-radius: 20px;
  background: white;
}

.data-input--district-school-info--charter-schools--authorizing-organizations--list .subheading-and-button-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.data-input--district-school-info--charter-schools--authorizing-organizations--list .new-button {
  display: inline-flex;
  width: auto;
  padding: 10px 15px;
}

.data-input--district-school-info--charter-schools--authorizing-organizations--list .table-container {
  width: calc(100% + 18px + 18px);
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: -18px;
}
