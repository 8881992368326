.data-input--student--non-public-student-entry-schedule--form .page--content {
  display: block;
  max-width: 1140px;
  margin-bottom: 300px;
}

.data-input--student--non-public-student-entry-schedule--form .field-row.field-row-grid-1-1-1 {
  grid: auto-flow / 1fr 1fr 1fr;
  display: grid;
  width: 100%;
}
