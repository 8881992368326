.data-input--course-codes--list .page--heading {
  flex-direction: column;
  align-items: flex-start;
  max-width: 728px;
}

.data-input--course-codes--list .page--heading .page--header-child {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: initial;
}

.data-input--course-codes--list .page--content {
  display: block;
  max-width: 1140px;
}

.data-input--course-codes--list .content-outer-container {
  grid-column: 1 / span 8;
  margin-bottom: 24px;
  padding: 24px;
  border-radius: 20px;
  background: var(--background--light-turquoise);
  font-size: 16px;
  line-height: 20px;
}

.data-input--course-codes--list .content-inner-container {
  padding: 22px 24px;
  border-radius: 20px;
  background: white;
}

.data-input--course-codes--list .subheading-and-button-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.data-input--course-codes--list .new-button {
  display: inline-flex;
  width: auto;
  padding: 10px 15px;
}

.data-input--course-codes--list .table-container {
  width: calc(100% + 18px + 18px);
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: -18px;
}

@media (max-width: 900px) {
  .data-input--course-codes--list .page--content {
    grid-column: 1 / span 12;
  }
}
