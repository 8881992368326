.data-input--course-codes--input .field-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.data-input--course-codes--input .field-row > * {
  margin-right: 30px;
}

.data-input--course-codes--input .field-row > *:last-child {
  margin-right: initial;
}

.data-input--course-codes--input .field-row.field-row-grid-1-1 {
  grid: auto-flow / 1fr 1fr;
  display: grid;
  width: 100%;
}

.data-input--course-codes--input .field-row.field-row-grid-1-2 {
  grid: auto-flow / 1fr 2fr;
  display: grid;
  width: 100%;
}

.data-input--course-codes--input .field-row.field-row-grid-1-3 {
  grid: auto-flow / 1fr 3fr;
  display: grid;
  width: 100%;
}

.data-input--course-codes--input .field-row.field-row-grid-2-1 {
  grid: auto-flow / 2fr 1fr;
  display: grid;
  width: 100%;
}

.data-input--course-codes--input .field-row.field-row-grid-1-3-2 {
  grid: auto-flow / 1fr 3fr 2fr;
  display: grid;
  width: 100%;
}

.data-input--course-codes--input .field-row.field-row-grid-1-1-1 {
  grid: auto-flow / 1fr 1fr 1fr;
  display: grid;
  width: 100%;
}

.data-input--course-codes--input .field-row.field-row-grid-2-1-1 {
  grid: auto-flow / 2fr 1fr 1fr;
  display: grid;
  width: 100%;
}

.data-input--course-codes--input .site-container .radio-list-container {
  grid: auto-flow / auto auto;
  display: grid;
  align-items: center;
}

.data-input--course-codes--input .carnegie-units-container .checkbox-list-container {
  grid: auto-flow / auto auto auto;
  display: grid;
  column-gap: 30px;
}

.data-input--course-codes--input .carnegie-units-container .checkbox-list-container .checkbox-list-option {
  min-width: 30px;
}

.data-input--course-codes--input .funding-codes-container .checkbox-list-container {
  grid: auto-flow / auto auto auto auto auto;
  display: grid;
  align-items: center;
}

.data-input--course-codes--input .sced-course-level-container .radio-list-container {
  grid: auto-flow / auto auto auto;
  display: grid;
  column-gap: 30px;
}

.data-input--course-codes--input .funding-codes-container .checkbox-list-container .checkbox-list-option {
  min-width: 100px;
}
