.data-quality-filter-container {
  display: flex;
  margin-bottom: 24px;
}

.data-quality-filter-container .dropdown-menu {
  width: auto;
  max-width: calc(100vw - 358px);
  white-space: nowrap;
}

.data-quality-filter-container .dropdown-option {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
