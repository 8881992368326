.files--upload--not-able-to-upload {
  padding: 32px;
  border-radius: 20px;
  background: var(--negative--background);
}

.files--upload--not-able-to-upload--no-file {
  margin-bottom: 20px;
  padding: 32px;
  border-radius: 20px;
  background: var(--negative--background);
}

.files--upload--not-able-to-upload h3 {
  margin-bottom: 12px;
  font-family: Quicksand, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.files--upload--not-able-to-upload--no-file h3 {
  margin-bottom: 12px;
  font-family: Quicksand, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.files--upload--not-able-to-upload--files {
  margin-bottom: 8px;
  list-style: none;
  word-break: break-word;
}

.files--upload--not-able-to-upload--no-files {
  margin-bottom: 8px;
  list-style: none;
  word-break: break-word;
}

.files--upload--not-able-to-upload--files li {
  display: flex;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid white;
}

.files--upload--not-able-to-upload--files li::before {
  content: '';
  display: block;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 6px;
  background: url('/public/images/icon-file.svg');
  background-repeat: no-repeat;
  background-size: contain;
  align-self: flex-start;
}

.files--upload--not-able-to-upload--file-name {
  font-family: Quicksand, sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.files--upload--not-able-to-upload--error {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  color: var(--negative--red);
}

.files--upload--not-able-to-upload--files li span {
  display: block;
}

.files--upload--not-able-to-upload--solutions {
  list-style: none;
}

.files--upload--not-able-to-upload--solutions li {
  margin-bottom: 20px;
}

.files--upload--not-able-to-upload--solutions li:last-child {
  margin-bottom: 0;
}
