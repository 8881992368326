.panel-content {
  display: flex;
  flex-flow: row wrap;
  padding: 10px 20px;
}

.panel-label-value {
  margin-bottom: 24px;
  padding-right: 24px;
}

.panel-label-value:last-child {
  padding-right: 0;
}

.panel-heading {
  padding: 10px 20px;
  border-radius: 20px 20px 0 0;
  font-family: Quicksand, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: var(--ink--medium);
}

.panel-block {
  border-width: 2px;
  border-radius: 20px;
  border-style: solid;
  border-color: var(--disabled--text);
}

.panel-block:not(:last-child) {
  margin-bottom: 24px;
}

.panel-info {
  background-color: var(--background--light-turquoise);
}

.panel-label {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: var(--ink--medium);
}

.panel-value {
  font-family: Quicksand, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: var(--ink--medium);
}
