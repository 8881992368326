.npse-schedule .subheading-and-button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.npse-schedule .subheading-and-button-container .subheading {
  font-family: Quicksand, sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
}

.npse-schedule .subheading-and-button-container .button-group {
  gap: 10px;
  display: flex;
  justify-content: flex-end;
}

.npse-schedule .content-inner-container {
  padding: 22px 0;
  border-radius: 20px;
  background: #fff;
}

.npse-schedule .subheading-and-button-container .new-button {
  display: inline-flex;
  width: auto;
  padding: 10px 15px;
}

.npse-schedule .content-outer-container {
  width: 100%;
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 20px;
}
