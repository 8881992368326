.data-input--district-school-info--salary-scale--list .content-outer-container {
  grid-column: 1 / span 8;
  margin-bottom: 24px;
  padding: 24px;
  border-radius: 20px;
  background: var(--background--light-turquoise);
  font-size: 16px;
  line-height: 20px;
}

.data-input--district-school-info--salary-scale--list .content-inner-container {
  padding: 22px 24px;
  border-radius: 20px;
  background: white;
}

.data-input--district-school-info--salary-scale--list .school-year-selection-container {
  gap: 10px;
  display: flex;
  align-items: center;
}

.data-input--district-school-info--salary-scale--list .button-group {
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: fit-content;
}

.data-input--district-school-info--salary-scale--list .cancel-button {
  display: block;
  width: 100px;
  padding: 4px 18px;
  box-sizing: border-box;
}

.data-input--district-school-info--salary-scale--list .submit-button {
  display: block;
  width: 100px;
  padding: 4px 18px !important;
}

.data-input--district-school-info--salary-scale--list .apply-all-button {
  display: block;
  margin-top: 25px;
  padding: 4px 18px;
  box-sizing: border-box;
}

.data-input--district-school-info--salary-scale--list .new-button {
  display: inline-flex;
  width: auto;
  padding: 10px 15px;
}

.data-input--district-school-info--salary-scale--list .table-container {
  width: calc(100% + 18px + 18px);
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: -18px;
}
