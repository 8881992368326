html,
body {
  height: 100%;
}

#login main {
  grid-column: 4 / span 6;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25%;
  margin-bottom: 100px;
  padding: 48px;
  border-radius: 40px;
  background: #fff;
  text-align: center;
}

#login {
  grid-template-columns: repeat(12, 1fr);
  gap: 20px;
  display: grid;
}

@media (max-width: 720px) {
  #login {
    grid-template-columns: repeat(4, 1fr);
    margin: 0 20px;
  }

  #login main {
    grid-column: 1 / span 4;
    min-height: 0;
    margin-top: 40px;
  }
}

#login main h1 {
  width: 290px;
  height: 110px;
  margin-top: 72px;
  margin-bottom: 12px;
  background: url('/public/images/logo.svg');
  text-indent: -9000px;
  background-size: contain;
  background-repeat: no-repeat;
}

@media (max-width: 720px) {
  #login main h1 {
    width: 190px;
    height: 70px;
  }
}

#login main p {
  margin-bottom: 32px;
  padding: 24px;
  font-family: Quicksand, sans-serif;
  font-size: 32px;
  font-weight: bold;
  line-height: 40px;
  color: var(--primary--navy-blue);
}

#login main .button {
  display: inline-flex;
  flex-grow: 0;
}

@media (max-width: 720px) {
  #login main .button {
    padding: 7px 24px;
  }
}

#login main .login--error.has-error {
  position: relative;
  margin-top: 24px;
  padding-left: 38px;
  font-size: 14px;
  line-height: 25px;
  color: var(--negative--red);
  text-align: left;
}

#login main .login--error.has-error::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 26px;
  height: 26px;
  background: url('/public/images/icon-failed.svg');
}
