.match-container label {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-weight: bold;
  line-height: 20px;
  color: var(--ink--medium);
}

.current-heading {
  margin: 8px;
  border-bottom: 2px;
  font-family: Quicksand, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: white;
  text-align: center;
}

.match-heading {
  margin: 8px;
  border-bottom: 2px;
  font-family: Quicksand, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: white;
  text-align: center;
}

.new-heading {
  margin: 8px;
  border-bottom: 2px;
  font-family: Quicksand, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: white;
  text-align: center;
}

.current-fields {
  flex-grow: 1;
  padding: 20px;
  border-radius: 0 0 17px 17px;
  background-color: var(--background--light-turquoise);
}

.match-fields {
  flex-grow: 1;
  padding: 20px;
  border-radius: 0 0 17px 17px;
  background-color: var(--background--light-turquoise);
}

.new-fields {
  padding: 20px;
  border-radius: 0 0 17px 17px;
  background-color: var(--background--light-turquoise);
}

.match-section {
  display: flex;
  flex-direction: column;
  border: 2px solid var(--disabled--text);
  border-radius: 20px;
  background-color: var(--primary--turquoise);
}

.current-section {
  display: flex;
  flex-direction: column;
  border: 2px solid var(--disabled--text);
  border-radius: 20px;
  background-color: var(--primary--navy-blue);
}

.new-section {
  margin-top: 24px;
  border: 2px solid var(--disabled--text);
  border-radius: 20px;
  background-color: var(--primary--navy-blue);
}

.field-value {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-weight: normal;
  line-height: 20px;
  color: var(--ink--medium);
}
