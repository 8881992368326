.expandable-navigation-item {
  margin-bottom: 8px;
}

.expandable-navigation-item--main {
  position: relative;
  display: flex;
  margin: 0 8px;
}

.expandable-navigation-item--main::before {
  content: '';
  position: absolute;
  z-index: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 22px;
}

.expandable-navigation-item--main:hover::before {
  background: var(--primary--turquoise);
  opacity: 0.25;
}

.expandable-navigation-item--main div {
  z-index: 100;
  display: flex;
  align-items: center;
  width: calc(100% - 44px);
  height: 44px;
  padding: 0 24px;
  border-radius: 22px 0 0 22px;
  font-family: Quicksand, sans-serif;
  font-size: 16px;
  color: inherit;
  text-decoration: none;
}

.expandable-navigation-item--main div::before {
  content: '';
  display: block;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  background: black;
}

.expandable-navigation-item--main div:hover {
  background: var(--primary--medium-turquoise);
  color: var(--ink--medium);
}

.expandable-navigation-item--main:hover {
  border-radius: 22px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 30%);
}

[data-is-active='true'] .expandable-navigation-item--main div {
  width: calc(100% - 16px);
  border-radius: 22px;

  /* background: var(--primary--turquoise);
  color: white; */
}

[data-is-active='true'] .expandable-navigation-item--toggle {
  display: none;
}

.expandable-navigation-item--main div:hover::before {
  background: var(--ink--medium);
}

/* [data-is-active="true"] .expandable-navigation-item--main a::before {
  background: white;
} */

.sidebar--nav--data-management::before {
  -webkit-mask-image: url('/public/images/icon-data-management.svg');
  mask-image: url('/public/images/icon-data-management.svg');
}

.expandable-navigation-item--toggle {
  -webkit-appearance: none;
  appearance: none;
  z-index: 100;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 44px;
  border: 0;
  border-left: 2px solid var(--background--light-turquoise);
  border-radius: 0 22px 22px 0;
  background: transparent;
}

.expandable-navigation-item--toggle span {
  display: none;
}

.expandable-navigation-item--toggle::before {
  content: '';
  display: block;
  width: 12px;
  height: 8px;
  background: var(--ink--medium);
  -webkit-mask-image: url('/public/images/icon-arrow-down.svg');
  mask-image: url('/public/images/icon-arrow-down.svg');
  transition: transform 0.35s ease-in-out;
}

.expandable-navigation-item--toggle:hover {
  background: var(--primary--medium-turquoise);
}

[data-is-open='true'] .expandable-navigation-item--toggle::before {
  transform: rotate(180deg);
}

.expandable-navigation-item--sublinks {
  position: relative;
  padding: 8px 0 8px 8px;
  padding-left: calc(8px + 24px + 12px - 1px + 8px);
  font-family: Quicksand, sans-serif;
  font-size: 16px;
}

.expandable-navigation-item--sublinks::before {
  content: '';
  position: absolute;
  top: 0;
  left: -10px;
  z-index: 0;
  display: block;
  width: calc(100% + 10px);
  height: 100%;
  box-shadow: inset 0 2px 4px rgb(0 0 0 / 30%);
  background: white;
}

[data-is-active='true'] .expandable-navigation-item--sublinks {
  border-left-color: var(--primary--navy-blue);
}

.expandable-navigation-item--sublinks li {
  position: relative;
  z-index: 1;
  margin-bottom: 8px;
}

.expandable-navigation-item--sublinks li:last-of-type {
  margin-bottom: 0;
}

.expandable-navigation-item--sublinks a {
  display: inline-flex;
  display: flex;
  align-items: center;
  width: calc(100% - 8px);
  padding: 10px 10px 10px 15px;
  box-sizing: border-box;
  border-radius: 20px;
  line-height: 20px;
  color: var(--ink--medium);
  text-decoration: none;
}

.expandable-navigation-item--sublinks a:hover,
.expandable-navigation-item--sublinks a.force-highlight,
.expandable-navigation-item--sublinks a[aria-current='page'] {
  box-shadow: 0 2px 4px rgb(0 0 0 / 30%);
  background: var(--primary--medium-turquoise);
  color: var(--ink--medium);
}

.expandable-navigation-item--sublinks a[aria-current='page'],
.expandable-navigation-item--sublinks a.force-highlight {
  box-shadow: none;
  background: var(--primary--turquoise);
  color: white;
}

@media (max-width: 720px) {
  .expandable-navigation-item {
    position: relative;
    margin-bottom: 0;
  }

  .expandable-navigation-item--main {
    position: relative;
    margin: 0;
  }

  .expandable-navigation-item--main div.expandable-navigation-item--main--link {
    justify-content: center;
    width: 44px;
    margin: 0;
    padding: 0;
  }

  .expandable-navigation-item--main div.expandable-navigation-item--main--link::before {
    margin-right: 0;
  }

  .expandable-navigation-item--main div.expandable-navigation-item--main--link span {
    display: none;
  }

  .expandable-navigation-item--toggle {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    border-left: 0;
    border-radius: 50%;
    background: transparent;
  }

  [data-is-active='true'] .expandable-navigation-item--toggle {
    display: flex;
  }

  .expandable-navigation-item--toggle:hover,
  [data-is-open='true'] .expandable-navigation-item--toggle {
    background-color: transparent;
  }

  .expandable-navigation-item--main div {
    border-radius: 50%;
  }

  .expandable-navigation-item--toggle::before {
    display: none;
  }

  .expandable-navigation-item--sublinks {
    position: absolute;
    top: 56px;
    left: 5px;
    width: 240px;
    margin-left: 0;
    padding-left: 8px;
    border-left: 0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 35%);
    background: white;
  }

  .expandable-navigation-item--sublinks::before {
    content: '';
    position: absolute;
    top: -12px;
    left: 16px;
    z-index: 10;
    width: 0;
    height: 100%;
  }

  .expandable-navigation-item--sublinks a {
    width: calc(100% - 16px);
  }

  [data-is-active='true'] .expandable-navigation-item--sublinks {
    display: none;
  }

  [data-is-open='true'] .expandable-navigation-item--sublinks {
    display: block;
  }

  [data-is-active='true'] .expandable-navigation-item--main a.expandable-navigation-item--main--link {
    background: var(--primary--turquoise);
    color: white;
  }

  [data-is-active='true'] .expandable-navigation-item--main a.expandable-navigation-item--main--link::before {
    background: white;
  }

  /* .sidebar--nav ul {
    display: flex;
    flex-direction: row;
    list-style: none;
  }

  .sidebar--nav ul li {
    margin-right: 8px;
    margin-bottom: 0;
  } */

  /* [data-is-active="true"] .expandable-navigation-item--main a {
    width: calc(100% - 44px);
    border-radius: 22px 0 0 22px;
  } */
}
