.edit-user .page--heading {
  margin-top: 24px;
  line-break: anywhere;
}

.edit-user .page-error-panel {
  margin-top: 0;
  margin-bottom: 24px;
}

.edit-user .action-row {
  gap: 24px;
  display: flex;
  justify-content: flex-end;
}

.edit-user .update-user-button {
  min-width: 150px;
}

.multi-selection-section {
  margin-bottom: 24px;
}

.assignment-section {
  display: flex;
  flex-direction: row;
  column-gap: 30px;
}

.assignment-section .panel-block {
  flex-grow: 1;
}

.assignment-section .panel-block:last-child {
  margin-bottom: 24px;
}

.empty--list-text {
  margin-top: 6px;
  text-align: center;
}

.empty--list-text p {
  margin-bottom: 0;
  padding: 6px;
  background-color: var(--background--light-gray);
  font-family: Quicksand, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: var(--primary--navy-blue);
}

.multi-selection-section .panel-content {
  flex-direction: column;
}

.multi-selection-section .button-group {
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 10px;
}

.multi-district-dropdown {
  margin-bottom: 24px;
}

.detail-loading-container {
  margin-top: 82px;
}

@media (max-width: 720px) {
  .edit-user .page--heading {
    margin-top: 0;
  }

  .assignment-section {
    flex-direction: column;
  }

  .data-domain-permission-selection {
    min-width: 100%;
  }
}
