.error-details {
  position: relative;
  padding: 32px;
  padding-left: 72px;
  border-radius: 20px;
  background: var(--negative--background);
}

.error-details::before {
  content: '';
  position: absolute;
  top: 32px;
  left: 32px;
  display: inline-block;
  width: 28px;
  height: 28px;
  background-image: url('/public/images/icon-failed.svg');
  background-size: contain;
}

.error-details p {
  margin-bottom: 20px;
}

.error-details--header {
  font-family: Quicksand, sans-serif;
  font-size: 20px;
  line-height: 24px;
}

.error-details--subheader {
  font-size: 14px;
  color: var(--negative--red);
}

.error-details--instructions {
  margin-top: 32px;
}

@media (max-width: 720px) {
  .error-details {
    padding: 16px;
    padding-left: 56px;
  }

  .error-details::before {
    top: 16px;
    left: 16px;
  }
}
