.bubble-outer {
  padding: 8px;
  text-align: center;
}

.bubble-container {
  width: 164px;
  height: 164px;
}

.bubble-container.success-bubble {
  background-image: url('/public/images/bubble-success.svg');
}

.bubble-container.warn-bubble {
  background-image: url('/public/images/bubble-warn.svg');
}

.bubble-container.fail-bubble {
  background-image: url('/public/images/bubble-failure.svg');
}

.bubble-container label.bubble-count {
  padding-top: 40px;
  font-family: Quicksand, sans-serif;
  font-size: 50px;
  font-weight: 600;
  line-height: 60px;
  text-align: center;
  font-style: normal;
}

.bubble-container label.bubble-count.bubble-med-text {
  font-size: 32px;
}

.bubble-container label.bubble-count.bubble-small-text {
  font-size: 20px;
}

.bubble-container label {
  font-family: Quicksand, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  font-style: normal;
}

.bubble-outer .title-text {
  max-width: 164px;
}

.bubble-outer .title-text-uppercase {
  text-transform: uppercase;
}

.bubble-outer .title-text-lowercase {
  text-transform: uppercase;
}

.bubble-outer .title-text-capitalize {
  text-transform: capitalize;
}

.bubble-container .success-text {
  color: var(--positive--dark--green);
}

.bubble-container .warn-text {
  color: var(--warning--dark-orange);
}

.bubble-container .fail-text {
  color: var(--negative--red);
}

.bubble-outer .clickable {
  cursor: pointer;
}

.badge-link {
  display: inline-block;
  border: 0;
  background: none;
  font-family: Quicksand, sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 30px;
  color: var(--primary--navy-blue);
  text-decoration: none;
  text-align: center;
  cursor: pointer;
}
