#root {
  min-width: 100%;
  min-height: 100%;
}

.error-boundary-container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  background-color: white;
}

.error-boundary-container section {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding: 20%;
}

.error-boundary-container section label {
  font-family: Quicksand, sans-serif;
  font-size: 70px;
  font-weight: 600;
  line-height: 80px;
  color: var(--primary--navy-blue);
  text-align: center;
}

.error-boundary-instruction {
  padding: 24px;
  font-family: Quicksand, sans-serif;
  font-size: 40px;
  line-height: 40px;
  color: var(--primary--navy-blue);
  text-align: center;
}
