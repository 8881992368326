.list-selection {
  display: flex;
  align-items: center;
  max-height: 110px;
  min-height: 110px;
}

.district-selection-container {
  flex-grow: 1;
}

.district-selection-container .style-single-dropdown-container {
  margin: 8px 12px;
}
