.page--not-found {
  position: relative;
  top: 30%;
  min-height: calc(100vh);
}

.page--not-found-section {
  text-align: center;
}

.page--not-found-section h1 {
  font-family: Quicksand, sans-serif;
  font-size: 190px;
  font-weight: 600;
  line-height: 190px;
  color: var(--primary--navy-blue);
}

.page--not-found-section h4 {
  font-family: Quicksand, sans-serif;
  font-size: 50px;
  font-weight: 600;
  line-height: 60px;
  color: var(--primary--navy-blue);
}

.page--not-found-section div {
  margin-top: 12px;
  font-family: Quicksand, sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: var(--ink--medium);
}

@media (max-width: 820px) {
  .page--not-found {
    min-height: calc(100vh - 135px);
  }

  .page--not-found-section h1 {
    font-size: 100px;
    line-height: 130px;
  }
}

@media (max-width: 420px) {
  .page--not-found {
    top: 25%;
  }

  .page--not-found-section h4 {
    font-size: 32px;
    line-height: 38px;
  }
}
