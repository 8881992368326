/* stylelint-disable declaration-colon-newline-after */
.progress {
  width: 100%;
}

.progress-text {
  font-family: Quicksand, sans-serif;
  font-size: 16px;
  line-height: 38px;
  text-align: center;
}

.pure-material-progress-linear {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 0.75em;
  border: none;
  border-radius: 50px;
  background-color: var(--background--light-turquoise);
  font-size: 16px;
  color: var(--primary--turquoise);
}

.pure-material-progress-linear::-webkit-progress-bar {
  background-color: transparent;
}

/* Determinate */
.pure-material-progress-linear::-webkit-progress-value {
  background-color: currentcolor;
  transition: all 0.2s;
}

.pure-material-progress-linear::-moz-progress-bar {
  background-color: currentcolor;
  transition: all 0.2s;
}

.pure-material-progress-linear::-ms-fill {
  border: none;
  background-color: currentcolor;
  transition: all 0.2s;
}

/* Indeterminate */
.pure-material-progress-linear:indeterminate {
  background-image: linear-gradient(
    to right,
    transparent 50%,
    currentcolor 50%,
    currentcolor 60%,
    transparent 60%,
    transparent 71.5%,
    currentcolor 71.5%,
    currentcolor 84%,
    transparent 84%
  );
  background-size: 200% 100%;
  animation: pure-material-progress-linear 2s infinite linear;
}

.pure-material-progress-linear:indeterminate::-moz-progress-bar {
  background-color: transparent;
}

.pure-material-progress-linear:indeterminate::-ms-fill {
  animation-name: none;
}

@keyframes pure-material-progress-linear {
  0% {
    background-size: 200% 100%;
    background-position: left -31.25% top 0%;
  }

  50% {
    background-size: 800% 100%;
    background-position: left -49% top 0%;
  }

  100% {
    background-size: 400% 100%;
    background-position: left -102% top 0%;
  }
}
