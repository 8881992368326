.login--loader {
  position: relative;
  margin-top: 20%;
}

.login--loader .progress {
  position: absolute;
  width: 70%;
  margin-left: 15%;
}

.login--loader .progress .progress-text {
  font-family: Quicksand, sans-serif;
  font-size: 50px;
  line-height: 65px;
  color: var(--background--light-turquoise);
  text-align: center;
}
