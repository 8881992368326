.data-domain-permission-selection {
  display: flex;
  flex-direction: column;
  min-width: 50%;
  padding: 18px 0;
}

.data-domain-permission-selection .field-container {
  margin-right: 0;
}

.data-domain-permission-selection .label-with-checkboxes {
  display: flex;
  max-width: 100%;
}

.data-domain-permission-selection .permission-name {
  max-width: 35%;
  min-width: 35%;
}

.data-domain-permission-selection .form-checkbox-container {
  flex-direction: row;
  max-height: 20px;
  margin-bottom: 8px;
}
