.report-item {
  padding: 24px;
  border: 3px solid var(--gray);
  border-radius: 20px;
  font-family: Quicksand, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: var(--ink--medium);
  text-decoration: none;
}

.report-info-display {
  display: flex;
  flex-direction: row;
}
