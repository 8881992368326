.match-display--title {
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 700;
  color: var(--primary--navy-blue);
}

.match-display--container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.match-display--separator {
  margin-right: 22px;
  margin-bottom: 22px;
  border-bottom: 1.5px solid var(--gray);
}
