.manual-input {
  --manual-input--content-size: 1140px;
}

.manual-input .page--heading {
  margin-bottom: 20px;
}

.manual-input .page--tab-bar {
  grid-column: 1 / span 12;
  gap: 24px;
}

.manual-input .manual-loading-container {
  grid-column: 2 / span 10;
}

.manual-input .manual-loading-container .progress {
  align-items: center;
  padding-top: 100px;
}

.manual-input .manual-button-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}

.manual-input .heading-button-error-container {
  margin-bottom: 12px;
}

.manual-input .page-error-panel {
  margin-top: 12px;
}

.manual-input .api-error-panel--info-message {
  margin-top: 12px;
}

.manual-input .manual-button-container .subheading {
  font-family: Quicksand, sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
}

.manual-input .manual-button-container .button-group {
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: fit-content;
}

.manual-input .required-label {
  font-size: 16px;
  line-height: 20px;
  color: var(--negative--red);
}

.manual-input .cancel-button {
  display: block;
  width: 100px;
  padding: 4px 18px;
  box-sizing: border-box;
}

.manual-input .submit-button {
  display: block;
  width: 100px;
  padding: 4px 18px !important;
}

.manual-input .submit-button:focus {
  padding: 0;
}

/* Accounts for last item to need more space (IE a dropdown) */
.manual-input .page--content {
  max-width: var(--manual-input--content-size);
  margin-bottom: 300px;
}

.manual-input .panel-content {
  row-gap: 20px;
}

.manual-input .panel-content .field-row .field-container:last-child {
  margin-right: 0;
}

@media (max-width: 900px) {
  .manual-input .page--content {
    grid-column: 1 / span 12;
  }
}
