.modal-field-container {
  display: flex;
  margin-right: 0;
  margin-bottom: 0;
}

.modal-field-container mark {
  display: flex;
  flex-direction: row;
  margin: 1px;
  padding: 3px;
  border-radius: 5px;
  background-color: var(--warning--light-orange);
}

.confirm-container p {
  margin-top: 8px;
  margin-bottom: 20px;
}

.match-container {
  grid-template-columns: 3fr 1fr 3fr;
  display: grid;
}

.match-operator {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

span.operator {
  width: 50px;
  font-size: 50px;
  color: var(--primary--navy-blue);
}

@media (max-width: 720px) {
  .match-container {
    display: flex;
    flex-direction: column;
    row-gap: 14px; /* Account for transform */
  }

  .match-operator span {
    transform: rotate(90deg);
  }
}
