.api-error-panel--container {
  margin-top: 24px;
  padding: 32px;
  border-radius: 20px;
  background-color: var(--negative--background);
}

.api-error-panel--title-container {
  display: flex;
  flex-direction: row;
}

.api-error-panel--title-shift {
  margin-left: 40px;
}

.api-error-panel--icon {
  width: 28px;
  height: 28px;
  margin-right: 12px;
  background: url('/public/images/icon-failed.svg');
  background-size: contain;
  align-self: flex-end;
}

.api-error-panel--title {
  flex-grow: 2;
  font-family: Quicksand, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: var(--ink--medium);
  font-style: normal;
  align-self: flex-end;
}

.api-error-panel--trace-id {
  margin-top: 8px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: var(--negative--red);
  font-style: normal;
}

.api-error-panel--content-detail {
  margin-top: 8px;
}

.api-error-panel--info-message {
  margin-top: 34px;
  color: var(--ink--medium);
}

.api-error-panel--info-message h4 {
  font-family: Quicksand, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.api-error-panel--info-message p {
  margin-top: 8px;
}
