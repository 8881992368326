.authenticated-page {
  height: 100%;
  background: white;
}

.authenticated-page main {
  grid-column: 2 / span 12;
}

.sidebar {
  grid-column: 1 / span 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 10px);
  max-height: calc(100vh - 10px);
  border-bottom: 10px solid var(--primary--turquoise);
  background: var(--background--light-turquoise);
  overflow-x: scroll;
}

.sidebar h1 {
  margin: 62px 32px 0;
}

.sidebar .sidebar--logo {
  max-width: 100%;
}

.sidebar .sidebar--logo--mobile {
  display: none;
}

.sidebar--nav {
  margin-bottom: auto;
}

.sidebar--nav ul {
  list-style: none;
}

.sidebar--nav > ul > li {
  margin-bottom: 8px;
}

.sidebar--nav ul li a.sidebar--nav--link {
  display: flex;
  align-items: center;
  height: 44px;
  margin: 0 8px;
  padding: 0 24px;
  border-radius: 22px;
  font-family: Quicksand, sans-serif;
  font-size: 16px;
  color: inherit;
  text-decoration: none;
}

.sidebar--nav ul li a.sidebar--nav--link::before {
  content: '';
  display: block;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  background: black;
}

.sidebar--nav ul li a.sidebar--nav--link:hover {
  background: var(--primary--medium-turquoise);
  color: var(--ink--medium);
}

.sidebar--nav ul li a.sidebar--nav--link[aria-current='page'] {
  background: var(--primary--turquoise);
  color: white;
}

.sidebar--nav ul li a.sidebar--nav--link:hover::before {
  background: var(--ink--medium);
}

.sidebar--nav ul li a.sidebar--nav--link[aria-current='page']::before {
  background: white;
}

.sidebar--nav--data-management::before {
  -webkit-mask-image: url('/public/images/icon-data-management.svg');
  mask-image: url('/public/images/icon-data-management.svg');
}

.sidebar--nav--admin::before {
  -webkit-mask-image: url('/public/images/icon-admin.svg');
  mask-image: url('/public/images/icon-admin.svg');
}

.sidebar--nav--students::before {
  -webkit-mask-image: url('/public/images/icon-students.svg');
  mask-image: url('/public/images/icon-students.svg');
}

.sidebar--nav--home::before {
  -webkit-mask-image: url('/public/images/icon-home.svg');
  mask-image: url('/public/images/icon-home.svg');
}

.sidebar--nav--reports::before {
  -webkit-mask-image: url('/public/images/icon-reports.svg');
  mask-image: url('/public/images/icon-reports.svg');
}

.sidebar--nav--manual-input::before {
  -webkit-mask-image: url('/public/images/icon-manual-entry.svg');
  mask-image: url('/public/images/icon-manual-entry.svg');
}

.sidebar--nav--feedback::before {
  -webkit-mask-image: url('/public/images/icon-checkmark.svg');
  mask-image: url('/public/images/icon-checkmark.svg');
}

.sidebar .sidebar--user {
  position: relative;
  margin-left: 32px;
  padding-bottom: 38px;
  font-family: Quicksand, sans-serif;
  font-size: 16px;
}

.sidebar .sidebar--user button {
  -webkit-appearance: none;
  appearance: none;
  display: flex;
  align-items: center;
  width: 100%;
  height: 44px;
  border: none;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
}

.sidebar .sidebar--user button span {
  max-width: 155px;
  color: var(--ink--medium);
  text-align: left;
}

.sidebar .sidebar--user button::after {
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  margin-left: 4px;
  border-radius: 12px;
  background: url('/public/images/icon-arrow-down.svg');
  background-repeat: no-repeat;
  background-position: center center;
}

.sidebar .sidebar--user button.sidebar--user--showing::after {
  background-color: var(--primary--turquoise);
  background-image: url('/public/images/icon-arrow-down-white.svg');
}

.sidebar .sidebar--user img {
  display: block;
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  margin-right: 12px;
  border-radius: 100%;
  background: var(--primary--turquoise);
  overflow: hidden;
}

.sidebar .sidebar--user .sidebar--user--menu {
  position: absolute;
  top: 44px;
  left: 42px;
  font-family: quicksand, sans-serif;
  font-size: 14px;
}

.sidebar .sidebar--user .sidebar--user--menu button {
  color: var(--ink--medium);
  text-decoration: none;
}

.sidebar .sidebar--user .sidebar--user--menu button::after {
  content: none;
}

@media (max-width: 720px) {
  .authenticated-page {
    height: auto;
  }

  .authenticated-page main {
    grid-row: 2;
    grid-column: 1 / span 4;
    max-height: unset;
    margin: 0 20px;
    overflow-y: hidden;
  }

  .sidebar {
    grid-column: 1 / span 4;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    height: 104px;
    padding-bottom: 0;
    overflow: visible;
  }

  .sidebar h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
    padding-top: 10px;
  }

  .sidebar .sidebar--logo {
    display: none;
  }

  .sidebar .sidebar--logo--mobile {
    display: inline;
    width: 128px;
  }

  .sidebar--nav {
    margin: 8px;
  }

  .sidebar--nav > ul {
    display: flex;
    flex-direction: row;
    list-style: none;
  }

  .sidebar--nav > ul > li {
    margin-right: 8px;
    margin-bottom: 0;
  }

  .sidebar--nav ul li a.sidebar--nav--link {
    justify-content: center;
    width: 44px;
    margin: 0;
    padding: 0;
  }

  .sidebar--nav ul li a.sidebar--nav--link::before {
    margin-right: 0;
  }

  .sidebar--nav ul li a.sidebar--nav--link span {
    display: none;
  }

  .sidebar .sidebar--user {
    margin-left: 0;
    padding-bottom: 0;
  }

  .sidebar .sidebar--user button span {
    display: none;
  }

  .sidebar .sidebar--user button::after {
    display: none;
  }

  .sidebar .sidebar--user .sidebar--user--menu {
    position: absolute;
    top: 56px;
    right: 0;
    left: auto;
    width: 240px;
    margin-left: 0;
    padding: 8px 0 8px 8px;
    padding-left: 8px;
    border-left: 0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 35%);
    background: white;
  }

  .sidebar .sidebar--user .sidebar--user--menu li {
    position: relative;
    z-index: 1;
    margin-bottom: 8px;
  }

  .sidebar .sidebar--user .sidebar--user--menu li:last-of-type {
    margin-bottom: 0;
  }

  .sidebar .sidebar--user .sidebar--user--menu button {
    display: inline-flex;
    display: flex;
    align-items: center;
    width: calc(100% - 8px);
    padding: 10px 10px 10px 15px;
    box-sizing: border-box;
    border-radius: 20px;
    line-height: 20px;
    color: var(--ink--medium);
    text-decoration: none;
  }

  .sidebar .sidebar--user .sidebar--user--menu button:hover {
    box-shadow: 0 2px 4px rgb(0 0 0 / 30%);
    background: var(--primary--medium-turquoise);
    color: var(--ink--medium);
  }
}
