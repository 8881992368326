.label-and-val--container {
  margin-right: 52px;
  margin-bottom: 24px;
}

.label-and-val--label {
  font-size: 14px;
}

.label-and-val--value {
  font-family: Quicksand, sans-serif;
  font-size: 18px;
  font-weight: bold;
  word-break: break-word;
}
