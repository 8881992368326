.field-row.field-row-sped-row-start {
  grid: auto-flow / 1fr 1fr 1fr 1fr;
  display: grid;
  width: 100%;
}

.field-row.field-row-sped-alt-rows {
  grid: auto-flow / 1fr 1fr 1fr;
  display: grid;
  width: 100%;
}
