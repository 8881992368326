.data-input--summer-activity--input .subheading-and-button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.data-input--summer-activity--input .field-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.data-input--summer-activity--input .field-row > * {
  margin-right: 30px;
}

.data-input--summer-activity--input .field-row > *:last-child {
  margin-right: initial;
}

.field-row.field-row-1-1-2-1 {
  grid: auto-flow / 1fr 1fr 2fr 1fr;
  display: grid;
  width: 100%;
}

.field-row.field-row-1-2-2 {
  grid: auto-flow / 1fr 2fr 2fr;
  display: grid;
  width: 100%;
}

.field-row.field-row-4-1-1 {
  grid: auto-flow / 4fr 1fr 1fr;
  display: grid;
  width: 100%;
}

.field-row.field-row-4 {
  grid: auto-flow / 4fr;
  display: grid;
  width: 100%;
}
