.organization-page .page--heading {
  flex-direction: column;
  align-items: flex-start;
  max-width: 728px;
  margin-bottom: 0;
}

.organization-page .page--heading .page--header-child {
  display: block;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: initial;
}

.organization-page .page--heading .page--header-child > p {
  padding-bottom: 20px;
}

.organization-page .page--content {
  display: block;
  max-width: 1140px;
}

@media (max-width: 900px) {
  .organization-page .page--content {
    grid-column: 1 / span 12;
  }
}
