.page--back-bar {
  grid-column: 1 / span 12;
  margin-top: 61px;
  padding: 6px 0;
  border-top: 1px solid var(--ink--light);
  border-bottom: 1px solid var(--ink--light);
  background: var(--background--light-turquoise);
}

.page--back-bar a {
  display: inline-flex;
  align-items: center;
  color: var(--ink--medium);
}

.page--back-bar a::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('/public/images/icon-back.svg');
}

@media (max-width: 720px) {
  .page--back-bar {
    margin-top: 0;
    margin-bottom: 24px;
  }
}
