@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.file-upload-status--name-and-status {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.file-upload-status--name {
  display: flex;
  min-height: 26px;
  font-family: Quicksand, sans-serif;
  font-size: 18px;
  line-height: 26px;
  line-break: anywhere;
}

.file-upload-status--name::before {
  content: '';
  display: block;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  background: url('/public/images/icon-file.svg');
}

.file-upload-status--status {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  font-size: 14px;
}

.file-upload-status--awaiting .file-upload-status--status::before {
  content: '';
  display: block;
  width: 26px;
  height: 26px;
  margin-right: 8px;
  background: url('/public/images/icon-pending.svg');
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.file-upload-status--completed .file-upload-status--status::before {
  content: '';
  display: block;
  width: 26px;
  height: 26px;
  margin-right: 8px;
  background: url('/public/images/icon-completed.svg');
}

.file-upload-status--failed .file-upload-status--status::before {
  content: '';
  display: block;
  width: 26px;
  height: 26px;
  margin-right: 8px;
  background: url('/public/images/icon-failed.svg');
}

/*
  display: block;
  width: 26px;
  height: 26px;
  background: url(""../images/icon-failed.svg")");
  content: ""; */

.file-upload-status progress {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  height: 14px;
  margin: 4px 0;
  border-radius: 7px;
  overflow: hidden;
}

.file-upload-status progress::-webkit-progress-bar {
  border: 1px solid var(--ink--medium);
  border-radius: 7px;
  background: var(--background--light-turquoise);
}

.file-upload-status progress::-webkit-progress-value {
  position: relative;
  top: -4px;
  height: 20px;
  border-radius: 10px;
  background: var(--primary--turquoise);
}

.file-upload-status--completed progress::-webkit-progress-value {
  background: var(--positive--green);
}

.file-upload-status--failed progress::-webkit-progress-value {
  background: var(--negative--red);
}

.file-upload-status--error {
  margin-top: 8px;
  font-size: 14px;
  color: var(--negative--red);
}
