.form-textarea-container > .data-input {
  height: initial;
  padding: 13px;
  font-family: Roboto, sans-serif;
}

.form-textarea-container.disabled input {
  background-color: var(--disabled--background);
  color: var(--disabled--text);
  border-color: var(--disabled--text);
}
