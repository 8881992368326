.msis-request-detail-matches {
  margin-top: 32px;
}

.msis-request-detail-matches h3 {
  margin-bottom: 6px;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: var(--primary--navy-blue);
}

.msis-request-detail-matches > .msis-request-detail-matches-subheading {
  font-size: 16px;
}

.msis-request-detail-matches > .msis-request-detail-match--container {
  margin-top: 24px;
  margin-right: 32px;
  border-width: 2px;
  border-radius: 10px 10px 20px 20px;
  border-style: solid;
  border-color: var(--disabled--text);
}

.msis-request-detail-matches > .msis-request-detail-match--container:nth-last-child(1) {
  margin-bottom: 24px;
}

.msis-request-detail-matches .alias-display {
  margin-bottom: 24px;
  padding-right: 22px;
}

.msis-request-detail-match--container > .msis-request-detail-match--heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 11px 20px 12px;
  border-radius: 10px 10px 0 0;
}

.msis-request-detail-match--heading .msis-request-detail-match-id {
  font-size: 18px;
  font-weight: 700;
}

.msis-request-detail-match--heading.strong-heading {
  background-color: var(--positive--light-green);
}

.msis-request-detail-match--heading.possible-heading {
  background-color: var(--warning--light-orange);
}

.msis-request-detail-match--heading.weak-heading {
  background-color: var(--negative--light-red);
}

.msis-request-detail-match--heading > .msis-request-detail-match-name {
  font-size: 18px;
  font-weight: 700;
  color: var(--ink--medium);
}

.msis-request-detail-match--heading > .msis-request-detail-match-strength-badge {
  display: flex;
  justify-content: center;
  min-width: 119px;
  margin-left: 15px;
  padding: 5px 0;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #fff;
}

.msis-request-detail-match-strength-badge.strong-badge {
  background-color: var(--positive--dark--green);
}

.msis-request-detail-match-strength-badge.possible-badge {
  background-color: var(--warning--dark-orange);
}

.msis-request-detail-match-strength-badge.weak-badge {
  background-color: var(--negative--red);
}

.msis-request-detail-match--container > .msis-request-detail-matched-data--container {
  padding-top: 25px;
  padding-bottom: 2px;
  padding-left: 22px;
}

/* .msis-request-detail-matched-data--container > .msis-request-detail-matched-data--title {
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 700;
  color: var(--primary--navy-blue);
} */

.match-action-container {
  padding-bottom: 20px;
  padding-left: 20px;
}

.new-match-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-separator {
  width: 100%;
  padding-top: 8px;
  border-bottom: 0.5px solid var(--ink--light);
  font-size: 14px;
  font-weight: 700;
  color: var(--ink--light);
  text-align: center;
}

@media (max-width: 720px) {
  .msis-request-detail-matches > .msis-request-detail-match--container {
    margin-right: 0;
  }
}
