.button-loading-ring {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  color: var(--disabled--text);
}

.button-loading-ring div {
  position: absolute;
  display: block;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  border: 8px solid currentcolor;
  border-radius: 50%;
  animation: button-loading-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentcolor transparent transparent;
}

.button-loading-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.button-loading-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.button-loading-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes button-loading-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
