.data-input--school-management--input .subheading-and-button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.data-input--school-management--input .field-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.data-input--school-management--input .field-row > * {
  margin-right: 30px;
}

.data-input--school-management--input .field-row > *:last-child {
  margin-right: initial;
}

.data-input--school-managemnt--input .field-row.field-row-grid-2-1-1-1 {
  grid: auto-flow / 5fr 1fr 1fr 1fr;
  display: grid;
  width: 100%;
}

.data-input--school-management--input .grades-container .checkbox-list-container {
  grid: auto-flow / auto auto auto auto auto;
  display: grid;
  align-items: center;
}

.data-input--school-management--input .grades-container .checkbox-list-container .checkbox-list-option {
  min-width: 60px;
}

.data-input--school-management--input .grades-container .non-all-option {
  padding-left: initial;
}

.lea-management-input-primary-info-child .panel-container-msis-primary-information {
  padding-bottom: 20px;
}

.school-id-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 1px;
  padding-bottom: 0;
  padding-left: 1px;
}

.school-id {
  display: flex;
  align-items: flex-start;
  height: 31px;
  margin-right: 0;
  padding-top: 1px;
  padding-bottom: 0;
  padding-left: 1px;
}
