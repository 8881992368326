.data-input--student--summer-activity--list .content-outer-container {
  grid-column: 1 / span 8;
  margin-bottom: 24px;
  padding: 24px;
  border-radius: 20px;
  background: var(--background--light-turquoise);
  font-size: 16px;
  line-height: 20px;
}

.data-input--student--summer-activity--list .content-inner-container {
  padding: 22px 24px;
  border-radius: 20px;
  background: white;
}

.data-input--student--summer-activity--list .subheading-and-button-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.data-input--student--summer-activity--list .new-button {
  display: inline-flex;
  width: auto;
  padding: 10px 15px;
}

.data-input--student--summer-activity--list .table-container {
  width: calc(100% + 18px + 18px);
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: -18px;
}

.data-input--student--summer-activity--new .action-row {
  gap: 10px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.data-input--student--summer-activity--new .cancel-button {
  width: 100px;
  padding: 4px 18px;
  box-sizing: border-box;
}

.data-input--student--summer-activity--new .submit-button {
  width: 100px;
  padding: 0 !important;
}

.data-input--student--summer-activity--new .field-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.data-input--student--summer-activity--list .school-year-selection-container {
  display: flex;
}

.data-input--student--summer-activity--list .school-selection {
  width: 400px;
  padding-left: 40px;
}

.data-input--student--summer-activity--list .subheading-container-padding {
  padding-top: 30px;
}

.data-input--student--summer-activity--list .msis-id {
  width: 10%;
}

.data-input--student--summer-activity--list .student-name {
  width: 20%;
}

.data-input--student--summer-activity--list .birth-date {
  width: 10%;
}

.data-input--student--summer-activity--list .grade-level {
  width: 10%;
}

.data-input--student--summer-activity--list .reason {
  width: 40%;
}

.data-input--student--summer-activity--list .verified {
  width: 10%;
}
