/* stylelint-disable font-family-no-missing-generic-family-keyword */
.powerbi-report .page--report {
  background: var(--background--light-turquoise);
  white-space: nowrap;
  overflow: auto;
}

.reports .report-style-class {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100vw;
}

.reports .header {
  top: 0;
  left: 0;
  height: 15px;
  border: 1px solid #707070;
  background: #3476ae 0 0 no-repeat padding-box;
}

.reports .displayMessage {
  margin-top: 2%;
  font: 22px/27px 'normal Segoe UI';
  color: #000;
  letter-spacing: 0;
  opacity: 1;
  text-align: center;
}

.reports .hr {
  border: 1px solid #e0e0e0;
  opacity: 1;
}

.reports .controls {
  margin-top: 1%;
  text-align: center;
}

.reports .title {
  margin: 13px;
  margin-left: 36px;
  font: bold 22px/27px 'Segoe UI';
  color: #fff;
  letter-spacing: 0;
  opacity: 1;
  text-align: left;
}

.reports button {
  width: 150px;
  height: 35px;
  margin-right: 15px;
  border: 0;
  border-radius: 5px;
  background: #337ab7;
  font-size: medium;
  color: #fff;
}

.reports button:focus {
  outline: none;
}

.reports iframe {
  border: none;
}
