.upload--button::before {
  content: '';
  display: block;
  width: 30px;
  height: 30px;
  margin-right: 8px;
  background: url('/public/images/icon-plus.svg');
}

.files--list table tbody tr[data-status='Failed'] td {
  background: var(--negative--background);
}

.files--list table tbody tr td div.files--list--actions {
  display: flex;
  justify-content: center;
}

.files--list table tbody tr td.files--list--action-container {
  vertical-align: middle;
}

.files--list td.files--list--status {
  padding: 10px 0;
  vertical-align: middle;
}

.files--list--actions button {
  border: none;
  background-color: transparent;
}

.download-button-img {
  width: 20px;
  height: 25px;
  background: url('/public/images/icon-download.svg');
}

.files--list--none {
  background: url('/public/images/icon-file-large.svg');
}

.files--list--file-name {
  word-break: break-all;
}

.files--list .status-filter {
  padding-top: 50px;
}

.files--list .uploaded-by-filter {
  padding-top: 50px;
  padding-right: 10px;
  padding-left: 30px;
}

@media (max-width: 720px) {
  .files--list .page-view-table tbody tr[data-status='Failed'] {
    background: var(--negative--background);
  }

  .files--list .page-view-table tbody td.files--list--file-name,
  .files--list .page-view-table tbody td.files--list--date,
  .files--list .page-view-table tbody td.files--list--uploaded-by {
    grid-column: 1;
  }

  .files--list .page-view-table tbody td.files--list--file-name {
    align-self: auto;
  }

  .files--list .page-view-table tbody td.files--list--date {
    grid-row: 2;
  }

  .files--list .page-view-table tbody td.files--list--uploaded-by {
    grid-row: 3;
  }

  .files--list .page-view-table tbody td.files--list--status {
    grid-row: 1;
    grid-column: 2;
    margin-bottom: 4px;
    padding: 0;
    justify-self: end;
  }
}

.files--list .file-detail-page--heading {
  margin-bottom: 0;
}

.files--list .file-detail-page--content {
  flex-direction: column;
}

.files--list .filter-container {
  display: flex;
  justify-content: space-between;
}

.files--list .filter-dropdown {
  margin-bottom: 12px;
}

.files--list .heading-buttons {
  gap: 24px;
  display: flex;
  align-items: center;
  padding-top: 40px;
}

.files--list .heading-buttons button {
  display: flex;
  max-height: 66px;
  min-height: 66px;
}

.files--list .heading-buttons a {
  display: flex;
}

.files--list table tbody tr td.files--list--action-container .action--column {
  justify-content: end;
}

.files--list .container {
  display: flex; /* Enable flexbox layout */
}

.files--list .apply-filter {
  display: flex;
  max-height: 66px;
  min-height: 66px;
  margin-top: 70px;
  margin-left: 30px;
}
