.certification-panel .grow-input {
  flex-grow: 1;
}

.detail-container .data-section {
  margin-bottom: 24px;
}

.detail-container .data-collection--heading {
  font-family: Quicksand, sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
}

.detail-container .data-collection--dates {
  gap: 20px;
  display: flex;
  flex-grow: 0.5;
  padding: 24px;
  border-radius: 20px;
  background-color: var(--warning--background-orange);
}

.detail-container .collection--label {
  flex-grow: 1;
  font-family: Quicksand, sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
}

.data-collection--list .collection--label {
  margin-bottom: 12px;
  font-size: 20px;
}

.collection--label.date-start-date {
  display: flex;
}

.detail-container .panel-block .panel-content {
  gap: 12px;
}

.certification-header-row {
  grid-template-columns: 0.5fr 1fr 0.5fr 1fr 0.5fr;
  display: grid;
}

.certification-row {
  grid-template-columns: 0.5fr 1fr 0.5fr 1fr 0.5fr;
  display: grid;
}

.detail-container .school-certification-counts {
  min-height: 42px;
  padding-left: 24px;
  background-color: var(--primary--navy-blue);
  color: white;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}

.school-certification-counts p {
  padding-top: 12px;
}
