.styled-checkbox {
  margin-top: 24px;
  margin-bottom: 24px;
}

.styled-checkbox .MuiFormControlLabel-label {
  font-family: Quicksand, sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  color: var(--primary--navy-blue);
}
