.files--upload input[type='file'] {
  display: block;
  width: 100%;
  margin-bottom: 24px;
  padding: 8px;
  box-sizing: border-box;
  border: 1.5px solid var(--ink--medium);
  border-radius: 10px;
}

.files--upload input[type='file'][aria-invalid='true'] {
  margin-bottom: 8px;
  border: 5px solid var(--negative--red);
}

.files--upload label {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
}

.files--upload .page--content {
  grid-column: 1 / span 10;
  display: block;
}

.files--upload--ready-to-upload,
.files--upload--not-able-to-upload,
.files--upload--buttons,
.files--upload--retry,
.files--upload--statuses {
  margin-bottom: 20px;
}

.files--upload--buttons button:first-child {
  margin-right: 32px;
  margin-bottom: 16px;
}

.files--upload--statuses .file-upload-status {
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--ink--light);
}

.input--error-message {
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 18px;
  color: var(--negative--red);
}

.files--upload--retry a:first-child {
  margin-right: 32px;
  margin-bottom: 16px;
}

@media (max-width: 900px) {
  .files--upload .page--content {
    grid-column: 1 / span 12;
  }

  .files--upload--ready-to-upload,
  .files--upload--not-able-to-upload,
  .files--upload--buttons,
  .files--upload--retry,
  .files--upload--statuses {
    grid-column: 1 / span 12;
  }
}
