.MuiButtonBase-root.tab-text {
  font-family: Quicksand, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  color: var(--ink--light);
  text-transform: none;
}

.MuiButtonBase-root.Mui-selected.tab-text {
  border-radius: 20px 20px 0 0;
  color: var(--primary--dark-blue);
}

.tab-content-container {
  padding: 20px;
  border-radius: 20px;
  background-color: var(--background--light-turquoise);
}

.tab-content {
  padding: 20px;
  border-radius: 20px;
  background-color: white;
}
