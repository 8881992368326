.data-input--lea-sharing--input .subheading-and-button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.data-input--lea-sharing--input .field-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.data-input--lea-sharing--input .field-row > * {
  margin-right: 30px;
}

.data-input--lea-sharing--input .field-row > *:last-child {
  margin-right: initial;
}

.field-row.field-row-1-1-1 {
  grid: auto-flow / 1fr 1fr 1fr;
  display: grid;
  width: 100%;
}
