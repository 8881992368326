.files--upload--ready-to-upload h3 {
  font-family: Quicksand, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: var(--primary--navy-blue);
}

.files--upload--ready-to-upload ul {
  list-style: none;
}

.files--upload--ready-to-upload ul li {
  display: flex;
  align-items: center;
  min-height: 56px;
  padding: 8px 0;
  border-bottom: 1px solid var(--background--gray);
  font-family: Quicksand, sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.files--upload--ready-to-upload ul li::before {
  content: '';
  display: block;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 6px;
  background: url('/public/images/icon-file.svg');
  background-repeat: no-repeat;
  background-size: contain;
}

.files--upload--ready-to-upload ul li span {
  margin-right: auto;
  word-break: break-all;
}

.files--upload--ready-to-upload ul li button {
  width: 44px;
  height: 44px;
  margin-right: 2px;
  border: 0;
  background: url('/public/images/icon-trash.svg');
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position: center center;
  text-indent: -9000px;
}
