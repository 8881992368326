.disabled .form-dropdown .control {
  border-color: var(--disabled--text);
}

.form-dropdown .control {
  min-height: 48px;
  box-sizing: content-box;
  border: 1.5px solid var(--ink--medium);
  border-radius: 10px;
  font-size: 16px;
}

.form-dropdown .control:focus-within {
  border-color: var(--primary--dark-blue);
}

.form-dropdown .dropdown-loading {
  /* Used for Testing hooks and later
     if we want to alter the loading */
  display: block;
}
