* {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}

main {
  max-height: calc(100vh - 42px);
  overflow-y: auto;
}

body {
  --background--light-turquoise: #eff8fa;
  --background--gray: #e3e3e3;
  --background--light-gray: #f8f8f8;
  --primary--dark-blue: #1f6698;
  --primary--navy-blue: #003b71;
  --primary--medium-blue: #3492d5;
  --primary--turquoise: #266b7e;
  --primary--medium-turquoise: #91cdde;
  --negative--red: #aa0e2a;
  --negative--light-red: #fac6d0;
  --negative--background: #fdecef;
  --ink--medium: #292940;
  --ink--light: #4e4f5f;
  --gray: #ececec;
  --positive--green: #739127;
  --positive--dark--green: #59711e;
  --positive--light-green: #d9eaae;
  --positive--background-green: #f7fbef;
  --disabled--text: #9b9b9b;
  --disabled--background: #f2f2f2;
  --warning--dark-orange: #a06304;
  --warning--background-orange: #fef7ec;
  --warning--light-orange: #ffdea7;

  background: var(--primary--turquoise);
  font: 16px/20px Roboto, sans-serif;
}

.grid {
  grid-template-columns: 274px repeat(12, 1fr);
  gap: 20px;
  display: grid;
}

.button {
  display: inline-block;
  padding: 15px 24px;
  border: 3px solid var(--primary--navy-blue);
  border-radius: 34px;
  background: none;
  font-family: Quicksand, sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 30px;
  color: var(--primary--navy-blue);
  text-decoration: none;
  text-align: center;
  cursor: pointer;
}

.button.new-button::before {
  content: '';
  display: block;
  width: 30px;
  height: 30px;
  margin-right: 8px;
  background: url('/public/images/icon-plus.svg');
}

.button.new-button:disabled::before {
  background: initial;
  background-color: var(--disabled--text);
  -webkit-mask-image: url('/public/images/icon-plus.svg');
  mask-image: url('/public/images/icon-plus.svg');
}

.input-text {
  margin-top: 8px;
  padding-left: 13px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 19px;
  color: var(--ink--light);
}

label {
  display: block;
  font-family: Quicksand, sans-serif;
  color: var(--ink--medium);
}

.button.button--small {
  padding: 7px 24px;
}

.button:hover.not.disabled {
  background: var(--background--light-turquoise);
  color: var(--primary--dark-blue);
  border-color: var(--primary--dark-blue);
}

.button:disabled {
  background-color: white;
  color: var(--disabled--text);
  border-color: var(--disabled--text);
  cursor: not-allowed;
}

.button.button--secondary {
  padding: 18px 27px;
  border: 3px solid var(--primary--navy-blue);
  background: var(--primary--navy-blue);
  color: white;
}

.button.button--small.button--secondary {
  padding: 9px 27px;
}

.button.button--secondary:focus {
  padding: 13px 22px;
  border: 5px solid var(--primary--medium-blue);
  outline: none;
}

.button.button--small.button--secondary:focus {
  padding: 4px 22px;
}

.button.button--secondary:hover.not.disabled {
  padding: 15px 24px;
  border: 3px solid var(--primary--navy-blue);
  background: var(--background--light-turquoise);
  color: var(--primary--navy-blue);
}

.button.button--small.button--secondary:hover.not.disabled {
  padding: 3px 22px;
}

.button.button--secondary:disabled {
  border: 3px solid var(--disabled--text);
  background-color: transparent;
  color: var(--disabled--text);
  cursor: not-allowed;
}

.no-wrap-text {
  white-space: nowrap;
}

.page--grid--d-6 {
  grid-column-end: span 6;
}

.page--grid--d-12 {
  grid-column-end: span 12;
}

.field-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 22px;
}

.data-input {
  height: 48px;
  padding-left: 13px;
  border: 1.5px solid var(--ink--medium);
  border-radius: 10px;
}

.date-input {
  flex-grow: 0;
}

.field-label {
  margin-bottom: 8px;
  font-family: Quicksand, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.is-invalid {
  border-color: var(--negative--red);
}

.is-group-invalid {
  padding-top: 4px;
  padding-right: 8px;
  padding-left: 8px;
  border: 1.5px solid var(--negative--red);
  border-radius: 10px;
}

.required-asterisk {
  color: var(--negative--red);
}

.forced-required-label::before {
  content: '* ';
  color: var(--negative--red);
}

.no-button-padding {
  padding: 0;
}

@media (max-width: 720px) {
  .page--grid--m-full {
    grid-column-end: span 12;
  }

  .grid {
    grid-template-columns: repeat(4, 1fr);
  }

  .field-container {
    flex-basis: 100%;
    margin-right: 0;
  }

  .field-label {
    margin-top: 18px;
  }
}
