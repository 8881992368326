/* stylelint-disable max-line-length */
.data-input--district-school-info--charter-schools--assignments--new .subheading-and-button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.data-input--district-school-info--charter-schools--assignments--new .subheading-and-button-container .subheading {
  font-family: Quicksand, sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
}

.data-input--district-school-info--charter-schools--assignments--new .subheading-and-button-container .button-group {
  gap: 10px;
  display: flex;
  justify-content: flex-end;
}

.data-input--district-school-info--charter-schools--assignments--new .cancel-button {
  width: 100px;
  padding: 4px 18px;
  box-sizing: border-box;
}

.data-input--district-school-info--charter-schools--assignments--new .submit-button {
  width: 100px;
  padding: 0;
}

.data-input--district-school-info--charter-schools--assignments--new .submit-button:focus {
  padding: 0;
}

.data-input--district-school-info--charter-schools--assignments--new .panel-content {
  row-gap: 20px;
}

.data-input--district-school-info--charter-schools--assignments--new .field-row {
  grid: auto-flow / 1fr 1fr;
  display: grid;
  width: 100%;
}

.data-input--district-school-info--charter-schools--assignments--new .field-row.date-selector-row {
  grid: auto-flow / 50% min-content min-content;
  display: grid;
  width: 100%;
}

.data-input--district-school-info--charter-schools--assignments--new .field-row > * {
  margin-right: 30px;
}

.data-input--district-school-info--charter-schools--assignments--new .field-row > *:last-child {
  margin-right: initial;
}
