.data-quality--detail .page--content {
  display: block;
}

.data-quality--detail .page--header {
  margin-top: 24px;
}

.data-quality--detail .three-dots {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 16px;
  height: 16px;
  margin-left: 10px;
  cursor: pointer;
}

.data-quality--detail .three-dots::before,
.data-quality--detail .three-dots::after,
.data-quality--detail .three-dots span {
  content: '';
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: black;
}

.data-quality--detail .data-quality-tools-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.data-quality--detail .tab-content-container {
  width: 100%;
}

.data-quality--detail .tab-content {
  overflow: auto;
}

.data-quality--detail .right-side-tools {
  display: flex;
  align-items: center;
}
