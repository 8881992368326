/* style overrides for React Material Dialog */
.MuiPaper-root.MuiPaper-rounded {
  max-width: 720px;
  max-height: calc(100vh - 200px);
  border-radius: 10px;
}

.MuiPaper-root.MuiPaper-rounded a {
  color: var(--primary--dark-blue);
}

.MuiTypography-root.MuiDialogTitle-root {
  flex-direction: row;
  padding: 0;
}

/* default styles for modal elements */
.modal-title-container {
  display: flex;
  flex-direction: row;
}

.modal-title {
  flex-grow: 2;
  font-family: Quicksand, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: var(--ink--medium);
  font-style: normal;
  align-self: flex-end;
}

.modal-close {
  position: relative;
  left: 12px;
  width: 30px;
  height: 30px;
  border-radius: 28px;
  background: none;
  font-family: Quicksand, sans-serif;
  font-size: 20px;
  color: var(--primary--turquoise);
  text-decoration: none;
  text-align: center;
  border-style: none;
}

.modal-close:hover {
  background: var(--primary--dark-blue);
  color: white;
}

.modal-content {
  display: flex;
  flex-direction: column;
  padding: 0 28px 28px;
}

/* style overrides for modal type Error */
.modal-type-error {
  padding: 32px;
  background-color: var(--negative--background);
}

.modal-type-error .modal-icon {
  width: 28px;
  max-width: 28px;
  min-width: 28px;
  height: 28px;
  max-height: 28px;
  min-height: 28px;
  margin-right: 12px;
  background: url('/public/images/icon-failed.svg');
  background-size: contain;
  align-self: flex-end;
}

.modal-type-error .modal-content {
  padding-right: 24px;
  padding-bottom: 0;
  padding-left: 40px;
}

/* style overrides for modal type Confirm */
.modal-type-confirm {
  background-color: var(--background--light-turquoise);
}

.modal-type-confirm .modal-title {
  margin-left: 28px;
  padding-top: 20px;
  font-family: Quicksand, sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
  color: var(--primary--navy-blue);
}

.modal-type-confirm .modal-close {
  position: inherit;
  left: 0;
  margin-right: 20px;
  margin-left: 20px;
  align-self: center;
}

/* style overrides for modal type Info */
.modal-type-info {
  padding: 20px;
  background-color: var(--background--light-turquoise);
}

.modal-type-info .modal-title {
  margin-left: 20px;
}

.modal-type-info .modal-close {
  position: inherit;
  left: 0;
  margin-right: 20px;
  margin-left: 20px;
  align-self: center;
}

/* media overrides */
@media (max-width: 720px) {
  .MuiPaper-root.MuiPaper-rounded {
    min-width: calc(100vw);
    min-height: calc(100vh);
  }

  .modal-type-error {
    min-width: calc(100vw - 64px);
    min-height: calc(100vh - 64px);
  }

  .modal-type-confirm {
    min-height: 100vh;
  }

  .modal-type-info {
    min-height: 100vh;
  }
}
