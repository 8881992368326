.data-input--student--non-public-student-entry--list .content-outer-container {
  grid-column: 1 / span 8;
  margin-bottom: 24px;
  padding: 24px;
  border-radius: 20px;
  background: var(--background--light-turquoise);
  font-size: 16px;
  line-height: 20px;
}

.data-input--student--non-public-student-entry--list .content-inner-container {
  padding: 22px 24px;
  border-radius: 20px;
  background: white;
}

.data-input--student--non-public-student-entry--list .subheading-and-button-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.data-input--student--non-public-student-entry--list .new-button {
  display: inline-flex;
  width: auto;
  padding: 10px 15px;
}

.data-input--student--non-public-student-entry--list .table-container {
  width: calc(100% + 18px + 18px);
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: -18px;
}

.data-input--student--non-public-student-entry--new .action-row {
  gap: 10px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.data-input--student--non-public-student-entry--new .cancel-button {
  width: 100px;
  padding: 4px 18px;
  box-sizing: border-box;
}

.data-input--student--non-public-student-entry--new .submit-button {
  width: 100px;
  padding: 0 !important;
}

.data-input--student--non-public-student-entry--new .field-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.data-input--student--non-public-student-entry--list .school-year-selection-container {
  display: flex;
}

.data-input--student--non-public-student-entry--list .school-year-padding {
  padding-bottom: 30px;
}
