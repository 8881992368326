.dashboard .page--grid {
  gap: 30px;
  margin-bottom: 48px;
}

.dashboard .page--heading {
  margin-right: 24px;
  margin-bottom: 16px;
  padding-bottom: 0;
  border-bottom: none;
}

.dashboard > time {
  display: block;
  margin-bottom: 32px;
  font-size: 14px;
  line-height: 18px;
}

.dashboard > time span {
  font-weight: bold;
}

.dashboard--grid section.empty-state {
  background: var(--background--light-gray);
}

.dashboard--grid section {
  min-height: 240px;
  padding: 48px;
  box-sizing: border-box;
  border-radius: 20px;
  background: var(--background--light-turquoise);
}

.dashboard--grid section.dashboard--grid--badge {
  display: flex;
  min-width: 300px;
  font-family: Quicksand, sans-serif;
  font-size: 32px;
  line-height: 38px;
}

.dashboard--grid section.dashboard--grid--badge::before {
  content: '';
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 24px;
  background-size: contain;
}

.dashboard--grid section.dashboard--grid--badge.status--success {
  align-items: center;
  background: var(--positive--background-green);
  color: var(--positive--green);
}

.dashboard--grid section.dashboard--grid--badge.status--failure {
  background: var(--negative--background);
  color: var(--negative--red);
}

.dashboard--grid section.dashboard--grid--badge.status--warning {
  background: var(--warning--background-orange);
  color: var(--warning--dark-orange);
}

.dashboard--grid section.dashboard--grid--badge.status--success::before {
  content: '';
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  width: 112px;
  height: 112px;
  margin-right: 24px;
  background: url('/public/images/icon-completed.svg');
  background-size: contain;
}

.dashboard--grid section.dashboard--grid--badge.status--failure::before {
  content: '';
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100px;
  height: 100px;
  margin-right: 24px;
  background: url('/public/images/icon-failed.svg');
  background-size: contain;
}

.dashboard--grid section.dashboard--grid--badge.status--warning::before {
  content: '';
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100px;
  height: 100px;
  margin-right: 24px;
  background: url('/public/images/icon-warning.svg');
  background-size: contain;
}

.button--dashboard--small {
  padding: 8px 12px;
}

.dashboard--status-message {
  margin-bottom: 12px;
}

.dashboard--grid--content h3 {
  margin-bottom: 12px;
  font-family: Quicksand, sans-serif;
  font-size: 20px;
  line-height: 24px;
}

.dashboard--grid--content dl dt,
.dashboard--upload-message {
  margin-bottom: 12px;
  font-family: Quicksand, sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  color: var(--primary--navy-blue);
}

.dashboard--upload-message {
  min-width: 266px;
}

.dashboard--grid--content dl dd {
  font-family: Quicksand, sans-serif;
  font-size: 18px;
  line-height: 22px;
}

.dashboard--grid--loader {
  margin-top: 100px;
  margin-right: 30px;
}

@media (max-width: 1400px) {
  .dashboard--grid section {
    padding: 32px;
  }

  .dashboard--grid section.dashboard--grid--badge {
    font-size: 24px;
    line-height: 28px;
  }

  .dashboard--grid section.dashboard--grid--badge::before {
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 920px) {
  .dashboard--grid section {
    min-height: 0;
  }

  .dashboard--grid section.dashboard--grid--badge,
  .dashboard--grid section.dashboard--grid--content {
    grid-column-end: span 12;
  }
}
