/* stylelint-disable max-line-length */
.data-input--district-school-info--charter-schools--management-organizations--new .subheading-and-button-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 20px;
}

.data-input--district-school-info--charter-schools--management-organizations--new .subheading-and-button-container .subheading-group {
  display: block;
}

.data-input--district-school-info--charter-schools--management-organizations--new .subheading-and-button-container .subheading {
  font-family: Quicksand, sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
}

.data-input--district-school-info--charter-schools--management-organizations--new .subheading-and-button-container .school-year-label {
  font-family: Quicksand, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

.data-input--district-school-info--charter-schools--management-organizations--new .subheading-and-button-container .button-group {
  gap: 10px;
  display: flex;
  height: fit-content;
}

.data-input--district-school-info--charter-schools--management-organizations--new .cancel-button {
  width: 100px;
  padding: 4px 18px;
  box-sizing: border-box;
}

.data-input--district-school-info--charter-schools--management-organizations--new .submit-button {
  width: 100px;
  padding: 0;
}

.data-input--district-school-info--charter-schools--management-organizations--new .submit-button:focus {
  padding: 0;
}

.data-input--district-school-info--charter-schools--management-organizations--new .panel-content {
  row-gap: 20px;
}

.data-input--district-school-info--charter-schools--management-organizations--new .field-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.data-input--district-school-info--charter-schools--management-organizations--new .field-row > * {
  margin-right: 30px;
}

.data-input--district-school-info--charter-schools--management-organizations--new .field-row > *:last-child {
  margin-right: initial;
}

.data-input--district-school-info--charter-schools--management-organizations--new .field-row.field-row-city-state-zip {
  grid: auto-flow / 2fr 1.5fr 1fr;
  display: grid;
  width: 100%;
}

.data-input--district-school-info--charter-schools--management-organizations--new .table-row {
  display: block;
  width: 100%;
}

.data-input--district-school-info--charter-schools--management-organizations--new .table-row .button-container {
  display: flex;
  justify-content: flex-end;
}

.data-input--district-school-info--charter-schools--management-organizations--new .new-button {
  display: inline-flex;
  width: auto;
  padding: 10px 15px;
}

.data-input--district-school-info--charter-schools--management-organizations--new .table-container {
  width: calc(100% + 14px + 14px);
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: -14px;
}

.data-input--district-school-info--charter-schools--management-organizations--new .page-view-table thead tr th:first-of-type {
  padding-left: 14px;
}
