.outcome-panel {
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--disabled--text);
}

.outcome-panel:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.outcome-panel .field-container {
  margin-top: 20px;
}

.outcome-panel .field-row-outcomes {
  grid: auto-flow / 2fr 1fr 1fr;
  display: grid;
  width: 100%;
}

.outcome-panel .field-row-outcomes.top-row {
  margin-top: 0;
}
