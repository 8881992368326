.form-checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
  align-self: center;
}

.form-checkbox-container .field-label {
  margin-bottom: initial;
}
