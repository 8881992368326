.student-owner-conflict-detail .page--heading {
  margin-top: 24px;
}

.msis-display {
  display: flex;
  margin-bottom: 8px;
}

.student-owner-conflict-detail .error-details {
  margin-bottom: 24px;
}

.student-owner-conflict-detail-district-content {
  display: flex;
  flex-flow: row wrap;
}

.date-conflict-field {
  display: flex;
  align-items: center;
  color: var(--negative--red);
}

.date-conflict-field::after {
  content: '';
  flex-grow: 0;
  flex-shrink: 0;
  width: 15px;
  height: 15px;
  margin-left: 6px;
  background: url('/public/images/icon-failed.svg');
  background-size: contain;
}

@media (max-width: 840px) {
  .student-owner-conflict-detail .page--content {
    display: block;
  }
}
