.report-subcategory-container .panel-content {
  gap: 12px;
}

.report-no-subcat-container {
  gap: 12px;
  display: flex;
  flex-flow: row wrap;
}

.report-item {
  max-width: calc(50% - 62px);
  min-width: calc(50% - 62px);
}

@media (max-width: 720px) {
  .report-item {
    flex-grow: 1;
    max-width: initial;
    min-width: initial;
  }
}
