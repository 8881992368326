.pill {
  display: inline-block;
  height: 28px;
  padding: 0 12px;
  border-radius: 14px;
  background: var(--gray);
  font-size: 14px;
  line-height: 28px;
  color: var(--ink--medium);
}

.file-status[data-status='Failed'] {
  background: var(--negative--red);
  color: white;
}

.file-status[data-status='Success'] {
  background: var(--positive--light-green);
}

.file-status[data-status='Pending'] {
  background: var(--background--gray);
}

.msis-request-status[data-status='Review Needed'] {
  height: auto;
  background: var(--warning--dark-orange);
  color: white;
}

.msis-request-status[data-status='ID Assigned'] {
  height: auto;
  background: var(--positive--light-green);
}

.msis-request-status[data-status='Existing ID Assigned'] {
  height: auto;
  background: var(--primary--medium-turquoise);
}

.msis-request-status[data-status='New ID Assigned'] {
  height: auto;
  background: var(--warning--light-orange);
}

.enrollment-status[data-status='Currently Enrolled'] {
  background: var(--positive--light-green);
}

.enrollment-status[data-status='Graduated'] {
  background: var(--background--gray);
}

.enrollment-status[data-status='Not Enrolled'] {
  background: var(--background--gray);
}

.user-status[data-status='Active'] {
  background: var(--positive--light-green);
}

.user-status[data-status='InActive'] {
  background: var(--background--gray);
}

.certification-status[data-status='Errors'] {
  background: var(--negative--red);
  color: white;
}

.certification-status[data-status='Certified'] {
  background: var(--positive--light-green);
}

.certification-status[data-status='Needs Certification'] {
  height: auto;
  background: var(--primary--navy-blue);
  color: white;
}

.certification-status[data-status='Outside of Date Range'] {
  background: var(--negative--light-red);
}
