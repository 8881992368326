.file-detail-page--grid {
  grid-template-rows: auto auto;
  grid-template-columns: repeat(12, 1fr);
  display: grid;
  margin-right: 30px;
  margin-left: 10px;
}

.file-detail-page--heading {
  grid-column: 1 / span 12;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 48px;
  margin-bottom: 24px;
  border-bottom: none;
  font-family: Quicksand, sans-serif;
  font-size: 50px;
  font-weight: 600;
  line-height: 62px;
  color: var(--primary--navy-blue);
}

.file-detail-page--header-child {
  gap: 24px;
  display: flex;
}

.file-detail-page--header-child .button {
  display: flex;
}

.file-detail-page-heading-content {
  gap: 24px;
  display: flex;
}

.file-detail-page-error-panel {
  grid-column: 1 / span 12;
}

.file-detail-page-pill-heading {
  display: flex;
  align-items: center;
}

.file-detail-page--content {
  grid-column: 1 / span 12;
  margin-bottom: 24px;
}

.file-detail-loading-container {
  grid-column: 2 / span 10;
  display: flex;
  min-height: calc(100vh - 450px);
}

.file-detail-loading-container .progress {
  align-self: center;
}

.file-detail-page--grid .empty-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  aspect-ratio: 1 / 1;
}

.file-detail-empty-content p {
  margin-bottom: 24px;
  font-family: Quicksand, sans-serif;
  font-size: 50px;
  line-height: 60px;
  color: var(--disabled--text);
}

.file-detail-empty-content a {
  color: var(--primary--navy-blue);
}

.file-detail-page-view-table {
  grid-column: 1 / span 12;
  width: 100%;
}

.file-detail-page-view-table thead {
  background: var(--background--light-turquoise);
  font-size: 16px;
  line-height: 20px;
}

.file-detail-page-view-table thead tr th {
  padding: 8px 0;
  border-top: 1px solid var(--ink--medium);
  border-bottom: 1px solid var(--ink--medium);
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  text-align: left;
}

.file-detail-page-view-table thead tr th:first-of-type {
  padding-left: 18px;
}

.file-detail-page-view-table tr {
  vertical-align: top;
}

.file-detail-page-view-table tbody td {
  padding: 18px 16px 18px 0;
  border-bottom: 1px solid var(--disabled--text);
  font-family: Quicksand, sans-serif;
  font-size: 16px;
  line-height: 20px;
}

.file-detail-page-view-table tbody td a {
  color: var(--primary--navy-blue);
}

.file-detail-page-view-table tbody td a:hover {
  color: var(--primary--dark-blue);
}

.file-detail-page-view-table tbody td:first-of-type {
  padding-left: 18px;
}

.file-detail-page-view-table .action--column {
  gap: 8px;
  display: flex;
}

.file-detail-page-view-table .action--column button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.file-detail-page-view-table .action--column button:not(:first-child) {
  margin-left: 4px;
}

@media (max-width: 720px) {
  .file-detail-page--grid {
    margin: 0;
  }

  .file-detail-page--grid--m-full {
    grid-column-end: span 12;
  }

  .file-detail-page--heading {
    display: block;
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 18px;
    font-size: 32px;
    line-height: 32px;
  }

  .file-detail-page--heading .button {
    display: inline-flex;
    width: auto;
  }

  .file-detail-page--header-child {
    margin-top: 24px;
  }

  .file-detail-page-view-table thead {
    display: none;
  }

  .file-detail-page-view-table,
  .file-detail-page-view-table td {
    display: block;
    padding-bottom: 0;
  }

  .file-detail-page-view-table tbody,
  .file-detail-page-view-table tbody tr {
    display: block;
  }

  .file-detail-page-view-table tbody tr {
    /* grid-template-columns: 215px 1fr; */
    display: grid;
    padding: 16px;
    border-bottom: 1px solid #9b9b9b;
  }

  .file-detail-page-view-table tbody td {
    padding: 0;
    padding-bottom: 4px;
    border-bottom: none;
  }

  .file-detail-page-view-table tbody td:first-of-type {
    padding-left: 0;
  }
}
