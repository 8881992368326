.page--unauthorized {
  position: relative;
  top: 30%;
  min-height: calc(100vh);
}

.page--unauthorized-section {
  text-align: center;
}

.page--unauthorized-section p {
  margin-bottom: 24px;
  font-family: Quicksand, sans-serif;
  font-size: 50px;
  line-height: 60px;
  color: var(--disabled--text);
}

.page--unauthorized-section div {
  grid-column: 4 / span 6;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
  text-align: center;
  aspect-ratio: 1 / 1;
}

@media (max-width: 720px) {
  .page--unauthorized {
    min-height: calc(100vh - 135px);
  }
}

@media (max-width: 400px) {
  .page--unauthorized {
    top: 25%;
  }
}
