.user-management--detail .page--heading {
  margin-top: 24px;
}

.user-management--detail .user-detail-success {
  margin-bottom: 24px;
}

.user-management--detail .label-and-val--container {
  flex-grow: 1;
}

.user-detail-layout {
  flex-direction: column;
}

.list-display {
  margin-top: 12px;
  margin-bottom: 24px;
}

.list-display h2 {
  margin-bottom: 12px;
  font-family: Quicksand, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: var(--primary--navy-blue);
}

.empty--list-text {
  margin-top: 6px;
  text-align: center;
}

.empty--list-text p {
  margin-bottom: 0;
  padding: 6px;
  background-color: var(--background--light-gray);
  font-family: Quicksand, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: var(--primary--navy-blue);
}

.confirm-container {
  margin-top: 24px;
}

@media (max-width: 720px) {
  .user-management--detail .page--heading {
    margin-top: 0;
  }
}
