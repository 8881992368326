.confirm-action-container {
  display: flex;
  margin-top: 24px;
  column-gap: 24px;
  row-gap: 24px;
}

.confirm--message {
  margin-top: 12px;
  font-family: Quicksand, sans-serif;
  color: var(--primary--navy-blue);
}

.item--display--confirm {
  padding: 12px;
}

.item--display--confirm ul {
  list-style-type: none;
}

@media (max-width: 720px) {
  .confirm-action-container {
    flex-direction: column;
  }
}
