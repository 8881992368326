@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.file-details .page--heading {
  margin-top: 24px;
  line-break: anywhere;
}

.file-details .error-details {
  margin-top: 12px;
  margin-bottom: 12px;
}

.file-details--container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.file-details--status {
  display: flex;
  align-items: center;
  font-size: 14px;
  column-gap: 2px;
}

.download-text {
  gap: 8px;
  display: flex;
}

.download-text::before {
  content: '';
  display: block;
  width: 20px;
  height: 25px;
  background: url('/public/images/icon-download.svg');
}

.file-details--status--message {
  display: flex;
  align-items: center;
  margin-top: 12px;
  font-size: 14px;
}

.file-details--status--message::before {
  content: '';
  display: inline-block;
  width: 22px;
  height: 22px;
  margin-right: 12px;
  background-size: contain;
}

.file-details--status--message[data-status='Success'] {
  color: var(--positive--dark--green);
}

.file-details--status--message[data-status='Failed'] {
  color: var(--negative--red);
}

.file-details--status--message[data-status='Success']::before {
  background-image: url('/public/images/icon-completed.svg');
}

.file-details--status--message[data-status='Failed']::before {
  background-image: url('/public/images/icon-failed.svg');
}

.file-details--status--message[data-status='Pending']::before {
  background-image: url('/public/images/icon-pending.svg');
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.file-details--status--details--header-list {
  margin-left: 30px;
  list-style: upper-alpha;
}

.file-details--status--details--header-list li {
  line-break: anywhere;
}

.file-details--content thead tr th {
  padding-left: 12px;
  white-space: nowrap;
}

.file-details--content tr td {
  padding-left: 12px;
}
