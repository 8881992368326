.new-user--intro {
  font-family: Quicksand, sans-serif;
  font-size: 24px;
  color: var(--primary--navy-blue);
}

.new-user--current-status {
  margin-top: 24px;
}

.new-user--next-steps {
  margin-top: 24px;
}

.new-user--next-steps p {
  margin-top: 12px;
  margin-bottom: 24px;
}

.new-user select {
  margin-top: 8px;
  margin-right: 22px;
  padding: 8px;
  border-radius: 10px;
}

.new-user--next-steps button {
  margin-top: 12px;
}

.new-user .info--panel--parent {
  margin-bottom: 24px;
}

.new-user .panel-content {
  flex-direction: column;
}

.new-user .api-error-panel--container {
  margin-top: 0;
  margin-bottom: 24px;
}
